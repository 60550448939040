.thumbnailImages {
    width: 300px;
    height: 250px;
    display: block;
}


.propertyRow {
    margin-top: 50px
}

.propertyRowAll {
    padding-bottom: 30px;
}

.propertyTitle {
    font-weight: 600;
    font-size: 22px;
    padding-top: 1rem;
}

.smallerButton {
   padding: 0.5rem 1rem !important;
}
.filterBy {
    margin-top: 7px;
    width: 70px;
}

.filterSelect {
    margin-right: 10px;
    margin-left: 10px;
}

.filterSelectLeft {
    margin-left: 10px;
}

.filterMiddle {
    margin-top: 7px;
}
.mobileFilter {
    display: block;
    margin-top: 10px;
    text-align: right;
    width: 100%;
    margin-right: 10px;
    color: #fff;
}

.allProperties .form-control {
    display: inline-block;
    width: 40%;
}

.propertyRight {
    text-align: right;
    margin-top: 10px;
}

.propertyRows .img-fluid {
    width: 400px;
    height: 250px;
}

.noResults {
    font-size: 2.5rem;
}

.section {
    background: linear-gradient(to top, rgb(37, 49, 76) 0%, rgb(39, 50, 76) 75%, #25304c 100%);

}

.noPaddingMobile {
    background: linear-gradient(to top, rgb(37, 49, 76) 0%, rgb(39, 50, 76) 75%, #25304c 100%);
}

.form-control {
    background-color: transparent !important;
    color: #021327;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #021327;
    opacity: 1; /* Firefox */
}

@media only screen and (max-width: 600px) {

    .paddingH2 {
        padding-bottom: 20px;
    }
    .section {
        margin-top: 540px;
    }

    .propertyMargin {
        margin-right: -15px !important;
        margin-left: -15px !important;
    }
    .img-fluid {
      display: block;
    }

   .mobileFilter {
       display: block;
       margin-top: 10px;
       text-align: right;
       width: 100%;
       margin-right: 10px;
       color: #fff;
   }

   .allProperties .form-control {
       display: inline-block;
       width: 40%;
       color: #021327;
   }

   .propertyMobile {
       margin-top: 10px;
   }

   .propertyRows {
       display: none;
   }

   .findYourPropertyMain {
    padding: 1rem !important;
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    /* width: 95% !important; */
    position: relative;
    top: 2px !important;
   }

   .noPaddingMobile {
       background: linear-gradient(to top, rgb(37, 49, 76) 0%, rgb(39, 50, 76) 75%, #25304c 100%);

   }

   .findPropertyMainTitle {
    font-size: 1.7rem !important;
}

.propertyFont {
    font-size: 0.9rem;
}

  }

  .img-fluid {
    object-fit: cover;
    height: 400px;
  }


  hr.solid {
    border-top: 3px solid #00246b;
  }
  
  .propertyDiv {
    margin-bottom: 10px;
  }

  .allPropertiesName h2 {
      font-size: 2rem;
  }

  .findPropertyMainTitle {
    text-align: center;
    letter-spacing: 1px;
    color: #F4F0EC;
    margin-bottom: 15px;
    font-size: 2.1rem;
    padding-top: 20px;
    letter-spacing: 2px;
}

.findYourPropertyMain {
    color: #fff;
    background: rgba(71, 78, 115, 0.5);
    padding: 20px 0px;
    position: relative;
    top: -350px;
}

.propertyCard {
    margin-bottom: 10px;
    color: #001029;
    
}

.propertyCardBackground {
    background:rgba(97 ,105 ,149, 0.4);
    padding-top: 30px;
    color: #F4F0EC;

}

h5 {
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;

}


.form-group label {
    font-weight: 700;
}
  

.main-form .form-control {
    background-color: transparent;
    color: #021327;
    border-radius: 1rem;

}

.height {
    height: 0px;
}

.main-form .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #021327;
    opacity: 1; /* Firefox */
}

.propertyMargin {
    margin-right: -100px;
    margin-left: -100px;
}

.btn-secondary.disabled, .btn-secondary:disabled {
    background-color:#F7CD33;
    border-color: #F7CD33;
    color: #021125;
}

.btn-secondary {
    background-color:#F7CD33 !important;
    border-color: #F7CD33 !important;
    color: #021125;

}


@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 

    .container-fluid {
        width: 85%;
    }

    .findYourPropertyMain {
       position: initial;
       top: 0;
       background-color: transparent !important;
    }

    .img-fluid {
        height: 550px;
    }

    .home {
    background-color: transparent !important;
    }

    .height {
        height: auto;
    }

}

@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 

    .container-fluid {
        width: 95%;
    }


  .form-row {
      display: block;
      margin-right: 15px;
      margin-left: 15px;
  }

  .findYourPropertyMain {
    position: initial;
    top: 0;
 }

 .landscape {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 15px !important;
    padding-right: 15px !important;
 }

 .section {
    margin-top: 540px;
 }

 .home {
    background-color: transparent !important;
    }

    .findYourPropertyMain {
        background-color: transparent !important;
     }
  

}

