
.navbar {
    padding: 1rem;
}
.navbar-background {
    color: #F4F0EC;
    // background: #ffffff;
    // background: linear-gradient(to left, #4d5b94, #7c83a6);
}

.navbar-logo {
    max-width: 180px;
    margin: 0 auto;
}

.navbar-nav {
    color: #021327;
}

.navbar-light .navbar-nav .nav-link {
    color: #021327;
    font-family: 'Jost', sans-serif;
    // text-transform: lowercase;
    font-weight: 700;
    margin-right: 2rem;
    margin-left: 2rem;
}


.navbar-light .navbar-nav .nav-link:active {
    color: #021327;
    // background-color: #00246b;
}

.navbar-light .navbar-nav .nav-link:visited {
    color:#021327;
    // background-color: #00246b;
}


.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.nav-link a {
    color: #021327;
    text-decoration: none;
}

.nav-link a:hover {
    color: #0b2247;
}

.dropdown-menu {
    background-color: #0b2247;
}

.dropdown menu .nav-link a  {
    color: #021327;
    background-color:  #fff;

}
.dropdown-item {
        color: #021327;
        background-color:  #fff;
}

.nav-link-span a {
    color: #021327;
    font-weight: 700;

}

.nav-link-span a:hover {
    color: #021327;
    text-decoration: none;

}

.dropdown menu .nav-link a:hover  {
    color: #021327;
    background-color:  #fff

}

@media only screen and (max-width:600px) {
    .navbar-background {
        color: #0b2247;
        background: #ffffff;
        background: linear-gradient(to left, #4d5b94, #7c83a6);
        top: -240px;
    }
}


@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 

    .navbar {

    margin-top: -240px;
    font-size: 13px;
    background: linear-gradient(to left, #4d5b94, #7c83a6);



    .mastheadMargin {
        margin-top: 150px;
    }
    }

}

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 

    .navbar-background {
        top: -240px;
    }

}