.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html,
body {
  overflow-x: hidden;
  background: linear-gradient(to top, rgba(37, 49, 76) 0%, rgba(39, 50, 76) 75%, #25304c 100%);
    height:100%;
  background: linear-gradient(to right, #e1e9f2, #f5faff);


}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main {
  padding-bottom: 4em;
  color: #F4F0EC;

}

h2 {
  color: #00246b;
}

.RichTextEditor__root { 
  border: 0;
}

.navbar-nav .dropdown-menu {
  border: none;
  width: 100%;
  border-radius: 0;
  min-width: 20rem;

}

.footer {
    height: 150px;
    color: #F4F0EC;
    font-weight: 600;
    background: #ffffff;
    background:linear-gradient(to top, #001029 0%, rgba(0, 41, 107, 0.9) 75%, rgba(0, 41, 107, 0.8) 100%);
    text-align: center;
}

.text {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.home {
  background: linear-gradient(to top, rgb(37, 49, 76) 0%, rgb(39, 50, 76) 75%, #25304c 100%);

}

.adminContainer {
margin-top: 2rem;
padding-top: 2rem;
background-color: #fff;
border-radius: 2rem;
color: #021327;
padding-bottom: 2rem;
}

.adminContainer h2 {
  color: #021327;
}

.adminContainer .form-control {
  color: #021327;
}
