.updateProperty {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-top: 20px;
}

.btnMargin {
    margin-left: 10px;
}

@media only screen and (max-width: 600px) {

.dzu-dropzone {
    width: 300px !important;
    height: 150px !important;

}

}