  .public-DraftEditor-content {
    min-height: 310px;
    overflow: auto;
  }


  .updateAbout {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-top: 20px;
}

.btn {
  font-size: 80%;
    border-radius: 5rem;
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem 3rem;
    transition: all 0.2s;
    background-color: #8391a0;
    border-color: #8391a0;
}

.btnMargin {
    margin-left: 10px;
}


.editorBackground {
  color: #000;
}

.body {
  background: #fff;
}

  